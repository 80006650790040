export default {
  MAIN_TITLE_P1: "DU WARST NOCH NIE SO",
  MAIN_TITLE_SPAN1: "NAH",
  MAIN_TITLE_P2: "BEI DEINEM",
  MAIN_TITLE_SPAN2: "IDOL",
  IMPRESSUM: "Impressum",
  FAQ: "FAQ",
  TERMS: "AGB",
  PRIVACY_POLICY: "Nutzungsbedingungen-Datenschutz",
  DATA_PROTECTION: "Datenverarbeitung",

  //Impressum:
  RESPONSIBLE_TITLE: "VERANTWORTLICH",
  RESPONSIBLE_DES:
    "für den Inhalt i.S.d. § 18 Abs. 2 MStV Bloggercube GmbH, Holstenplatz 20, 22765 Hamburg",
  MANAGING: "Bloggercube GmbH, Holstenplatz 20, 22765 Hamburg GESCHÄFTSFÜHRUNG",
  MANAGMENT: "Mehdi Ghahreman",
  MANAGING_TEL: "Tel. 0800 0049 040",
  MANAGING_TAX: "UST.-IDENTNR: DE 327082599",
  MANAGING_REGISTRATION_NUMBER: "Amtsgericht Hamburg HRB 157341",

  SHAREHOLDERS_TITLE: "VERTRETEN DURCH DIE GESELLSCHAFTER",
  SHAREHOLDERS_DES:
    "Mehdi Ghahreman, Niklas Imaz de Zavellá, Nils Wesch, Anton Happel",
  SHAREHOLDERS_TEL: "Tel: +49 40607762810",
  SHAREHOLDERS_EMAIL: "Email: management@bloggercube.com",

  PROTECTION_TITLE: "JUGENDSCHUTZBEAUFTRAGTER",
  PROTECTION_DES: "Alex Mätin (E-Mail: Jugendschutz@bloggercube.com)",
  PRTECATION_S_TITLE: "Bloggercube GmbH",
  PROTECTION_DES2: "Holstenplatz 20",
  PROTECTION_DES3: "22765 Hamburg",

  DATA_PROTECTION_TITLE: "DATENSCHUTZ",
  DATA_PROTECTION_DES:
    "Verantwortlich für die Datenverarbeitung gemäß Art. 4 Nr. 7 DSGVO ist vorbehaltlich abweichender Angaben in dieser Datenschutzerklärung die Bloggercube GmbH,",
  DATA_PROTECTION_OFFICER:
    "Rechtsanwalt Jens Thurn, Holstenplatz 20, 22765 Hamburg, Telefon: +49 40 6077 62810 ",

  LEGAL_TITLE: "RECHTSHINWEISE",
  LEGAL_COPYRIGHT: "© Copyright Bloggercube GmbH 2017-2021",
  LEGAL_DES1: "Alle Rechte vorbehalten.",
  LEGAL_DES2:
    "Der Inhalt dieser Website ist urheberrechtlich geschützt. Nachdruck, Aufnahme in Online-Dienste, Internet und Vervielfältigung auf Datenträger wie CD-ROM, DVD-ROM usw. dürfen, auch auszugsweise, nur nach vorheriger, schriftlicher Zustimmung durch Bloggercube GmbH erfolgen. Eine kommerzielle Weitervermarktung des Inhalts ist untersagt. Bloggercube GmbH haftet nicht für unverlangt eingesandte Inhalte, Manuskripte und Fotos und Videos. Für Inhalte externer Links und fremde Inhalte übernimmt Bloggercube GmbH keine Verantwortung.",
  LEGAL_DES3:
    "Es gelten die Allgemeinen Geschäftsbedingungen für die Nutzung von Bloggercube GmbH. Zum Ansehen einiger Dateien dieser Website benötigen Sie den Acrobat Reader, den Sie kostenlos downloaden können",
  LEAGL_LINK: "hier",
};

import React, { memo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Images = () => {
  const settings = {
    useCss: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          // centerPadding: "50px",
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          dots: false,
          centerPadding: "20px",
        },
      },
    ],
  };
  return (
    <>
      <div className="images__container">
        <div className="images__col">
          <div className="image__container">
            <img src="./assets/images/1.jpg" />
          </div>
          <div className="image__container">
            <div className="shadow" />
            <img src="./assets/images/2.jpg" />
          </div>
        </div>
        <div className="images__col">
          <div className="image__container">
            <img src="./assets/images/3.jpg" />
          </div>
          <div className="image__container">
            <div className="shadow" />
            <img src="./assets/images/4.jpg" />
          </div>
        </div>
        <div className="images__col">
          <div className="image__container">
            <div className="shadow" />
            <img src="./assets/images/5.jpg" />
          </div>
        </div>
        <div className="images__col">
          <div className="image__container">
            <div className="shadow" />
            <img src="./assets/images/6.jpg" />
          </div>
        </div>
        <div className="images__col">
          <div className="image__container">
            <div className="shadow" />
            <img src="./assets/images/7.jpg" />
          </div>
        </div>
        <div className="images__col">
          <div className="image__container">
            <img src="./assets/images/8.jpg" />
          </div>
          <div className="image__container">
            <div className="shadow" />
            <img src="./assets/images/9.jpg" />
          </div>
        </div>
        <div className="images__col">
          <div className="image__container">
            <img src="./assets/images/10.jpg" />
          </div>
          <div className="image__container">
            <div className="shadow" />
            <img src="./assets/images/11.jpg" />
          </div>
        </div>
      </div>
      <div className="images__container--size-s">
        <Slider {...settings}>
          {/* <div className="swiper-slide"> */}
          <div className="image__container">
            <img src="./assets/images/2.jpg" />
          </div>
          {/* </div> */}
          {/* <div className="swiper-slide"> */}
          <div className="image__container">
            <img src="./assets/images/3.jpg" />
          </div>
          {/* </div> */}
          {/* <div className="swiper-slide"> */}
          <div className="image__container">
            <img src="./assets/images/4.jpg" />
          </div>
          {/* </div> */}

          {/* <div className="swiper-slide"> */}
          <div className="image__container">
            <img src="./assets/images/6.jpg" />
          </div>
          {/* </div> */}
          {/* <div className="swiper-slide"> */}
          <div className="image__container">
            <img src="./assets/images/7.jpg" />
          </div>
          {/* </div> */}
          {/* <div className="swiper-slide"> */}
          <div className="image__container">
            <img src="./assets/images/8.jpg" />
          </div>
          {/* </div> */}
          {/* <div className="swiper-slide"> */}
          <div className="image__container">
            <img src="./assets/images/9.jpg" />
          </div>
          {/* </div> */}
          {/* <div className="swiper-slide"> */}
          <div className="image__container">
            <img src="./assets/images/10.jpg" />
          </div>
          {/* </div> */}
          {/* <div className="swiper-slide"> */}
          <div className="image__container">
            <img src="./assets/images/11.jpg" />
          </div>
          {/* </div> */}
          {/* <div className="swiper-slide"> */}
          <div className="image__container">
            <img src="./assets/images/5.jpg" />
          </div>
          {/* </div> */}
          {/* <div className="swiper-slide"> */}
          <div className="image__container">
            <img src="./assets/images/1.jpg" />
          </div>
          {/* </div> */}
        </Slider>
      </div>
    </>
  );
};

export default memo(Images);

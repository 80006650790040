import React, { memo, useContext } from "react";
import "./assets/styles/Main.scss";
import { Context } from "./Provider";
import Images from "./Images";
import Footer from "./Footer";

const Home = () => {
  const context = useContext(Context);
  const { translate, handleLanguage } = context;

  return (
    <div className="Home">
      <div className="header">
        <h1>
          <div className="logo">
            <img src={"./assets/images/logo.png"} alt="Bloggercube" />
          </div>
          <p>
            BLOGGER<span>CUBE</span>
          </p>
        </h1>
        <div className="langs">
          <div className="lang" onClick={() => handleLanguage("DE")}>
            <img src={"./assets/images/DE.png"} alt="German" />
          </div>
          <div className="lang" onClick={() => handleLanguage("EN")}>
            <img src={"./assets/images/EN.png"} alt="English" />
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="content">
          <p>
            {translate.MAIN_TITLE_P1}
            <br />
            <span>{translate.MAIN_TITLE_SPAN1}</span> {translate.MAIN_TITLE_P2}{" "}
            <span>{translate.MAIN_TITLE_SPAN2}</span>
          </p>
          <div className="btn__container">
            <a
              href="https://apps.apple.com/de/app/bloggercube/id1546230121"
              target="_blank"
            >
              <img src={"./assets/images/app-store.png"} alt="App Store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.bloggercube"
              target="_blank"
            >
              <img src={"./assets/images/play-store.png"} alt="Play Store" />
            </a>
          </div>
        </div>
        <Images />
      </div>
      <Footer />
    </div>
  );
};

export default memo(Home);

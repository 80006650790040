export default {
  MAIN_TITLE_P1: "YOU HAVE NEVER BEEN SO",
  MAIN_TITLE_SPAN1: "CLOSE",
  MAIN_TITLE_P2: "TO YOUR",
  MAIN_TITLE_SPAN2: "IDOL",
  IMPRESSUM: "Impressum",
  FAQ: "FAQ",
  TERMS: "Terms & Conditions",
  PRIVACY_POLICY: "Privacy Policy",

  //Impressum:
  RESPONSIBLE_TITLE: "RESPONSIBLE",
  RESPONSIBLE_DES:
    "for the content as of german law § 18 Abs. 2 MstV Bloggercube GmbH, Holstenplatz 20, 22765 Hamburg",

  MANAGING: "Bloggercube GmbH,Holstenplatz 20, 22765 Hamburg",
  MANAGMENT: "Mehdi Ghahreman",
  MANAGING_TEL: "Tel. +49 (0) 0800 0049 040",
  MANAGING_TAX: "Tax ID_DE327082599",
  MANAGING_REGISTRATION_NUMBER: "Company registration number: HRB 157341",

  SHAREHOLDERS_TITLE: "Represented by the shareholders",
  SHAREHOLDERS_DES:
    "Mehdi Ghahreman, Niklas Imaz de Zavellá, Nils Wesch, Anton Happel",
  SHAREHOLDERS_TEL: "Tel: +49 40607762810",
  SHAREHOLDERS_EMAIL: "Email: management@bloggercube.com",

  PROTECTION_TITLE: "responsible for the protection of minors",
  PROTECTION_DES: "Alex Mätin (E-Mail: Jugendschutz@bloggercube.com)",
  PRTECATION_S_TITLE: "Bloggercube GmbH",
  PROTECTION_DES2: "Holstenplatz 20",
  PROTECTION_DES3: "22765 Hamburg",

  DATA_PROTECTION_TITLE: "Data protection and privacy policy",
  DATA_PROTECTION_DES: "www.thurn-legal.de",
  DATA_PROTECTION_OFFICER:
    "Rechtsanwalt Jens Thurn, Holstenplatz 20, 22765 Hamburg, Telefon: +49 40 6077 62810 ",

  LEGAL_TITLE: "LEGAL NOTICES",
  LEGAL_COPYRIGHT: "© Copyright Bloggercube GmbH 2017-2021",
  LEGAL_DES1: "All rights reserved.",
  LEGAL_DES2:
    "The content of this website and the app available in PlayStore and AppleStore are protected by copyright. Any kind of copies of the content, pictures, design need a prior written agreement and approval stated by Bloggercube GmbH. Any commercial action with the content is prohibited by law. Bloggercube GmbH is not responsible for any content or media sent in by third parties. Bloggercube GmbH is not responsible for the content from third party links and the content provided there. ",
  LEGAL_DES3:
    "Official Terms and Conditions apply to the usage of Bloggercube GmbH and their Products. For the viewing of documents you might need Acrobat Reader. You can find a download option",
  LEAGL_LINK: "here",
};

import React, { memo, useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "./Provider";

const Impressum = () => {
  const context = useContext(Context);
  const { translate } = context;

  return (
    <div className="impressum">
      <Link to="/">
        <div className="logo__container">
          <img
            className="logo"
            src="./assets/images/BC_LOGO.png"
            alt="Bloggercube"
          />
        </div>
      </Link>
      <h1>IMPRESSUM</h1>
      <div className="block">
        <h2>{translate.RESPONSIBLE_TITLE}</h2>
        <p>{translate.RESPONSIBLE_DES}</p>
      </div>
      <div className="block">
        <h2>{translate.MANAGING}</h2>
        <p>{translate.MANAGMENT}</p>
        <p>{translate.MANAGING_TEL}</p>
        <p>{translate.MANAGING_TAX}</p>
        <p>{translate.MANAGING_REGISTRATION_NUMBER}</p>
      </div>
      <div className="block">
        <h2>{translate.SHAREHOLDERS_TITLE}</h2>
        <p>{translate.SHAREHOLDERS_DES}</p>
        <p>{translate.SHAREHOLDERS_TEL}</p>
        <p>{translate.SHAREHOLDERS_EMAIL}</p>
      </div>
      <div className="block">
        <h2>{translate.PROTECTION_TITLE}</h2>
        <p>{translate.PROTECTION_DES}</p>
        <h3>{translate.PRTECATION_S_TITLE}</h3>
        <p>{translate.PROTECTION_DES2}</p>
        <p>{translate.PROTECTION_DES2}</p>
      </div>
      <div className="block">
        <h2>{translate.DATA_PROTECTION_TITLE}</h2>
        <p>{translate.DATA_PROTECTION_DES}</p>
        <p>{translate.DATA_PROTECTION_OFFICER}</p>
      </div>

      <div className="block notice">
        <h2>{translate.LEGAL_TITLE}</h2>
        <p>{translate.LEGAL_COPYRIGHT}</p>
        <p>{translate.LEGAL_DES1}</p>
        <p>{translate.LEGAL_DES2}</p>
        <p>
          {translate.LEGAL_DES3}{" "}
          <a href="https://acrobat.adobe.com/de/de/acrobat/pdf-reader.html">
            {translate.LEAGL_LINK}.
          </a>
        </p>
      </div>
    </div>
  );
};

export default memo(Impressum);

import React, { memo, useContext } from "react";
import { Link } from "react-router-dom";
import "./assets/styles/Main.scss";
import Images from "./Images";
import { Context } from "./Provider";

const Footer = () => {
  const context = useContext(Context);
  const { translate, language, handleLanguage } = context;

  const options =
    language === "EN"
      ? [
          {
            id: 1,
            title: translate.TERMS,
            link: "https://bloggercube.com/Terms-and-Conditions.pdf",
          },
          {
            id: 2,
            title: translate.PRIVACY_POLICY,
            link: "https://bloggercube.com/Privacy-Policy.pdf",
          },
        ]
      : [
          {
            id: 1,
            title: translate.TERMS,
            link: "https://bloggercube.com/AGB.pdf",
          },
          {
            id: 2,
            title: translate.PRIVACY_POLICY,
            link: "https://bloggercube.com/Nutzungsbedingungen-Datenschutz.pdf",
          },
          {
            id: 3,
            title: translate.DATA_PROTECTION,
            link: "https://bloggercube.com/Datenverarbeitung.pdf",
          },
          {
            id: 4,
            title: translate.FAQ,
            link: "https://bloggercube.com/FAQ.pdf",
          },
        ];

  return (
    <div className="footer">
      <p>Copyright &#169; 2021 Bloggercube GmbH</p>
      <div className="ruls">
        <Link to={"/Impressum"}>
          <p>Impressum</p>
        </Link>
        {options.map((option) => (
          <a
            href={option.link}
            target="_blank"
            rel="noreferrer"
            key={option.id}
          >
            <p>{option.title}</p>
          </a>
        ))}
      </div>
    </div>
  );
};

export default memo(Footer);

import React, { memo } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./assets/styles/Main.scss";
import Home from "./Home";
import Impressum from "./Impressum";

const App = () => {
  return (
    <div className="App">
      <Route exact path="/" component={Home} />
      <Route path="/Impressum" component={Impressum} />
    </div>
  );
};

export default memo(App);

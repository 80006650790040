import React, { createContext, memo, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import EN from "./local/EN";
import DE from "./local/DE";
import App from "./App";

const Context = createContext();

const Provider = () => {
  const [language, setLanguage] = useState({
    translate: EN,
    language: "EN",
  });

  const handleLanguage = (language) => {
    if (language === "EN") {
      setLanguage({
        translate: EN,
        language: "EN",
      });
    }
    if (language === "DE") {
      setLanguage({
        translate: DE,
        language: "DE",
      });
    }
  };

  return (
    <Context.Provider value={{ ...language, handleLanguage }}>
      <Router>
        <App />
      </Router>
    </Context.Provider>
  );
};

export { Context };
export default memo(Provider);
